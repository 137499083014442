<template>
  <el-select  :filterable="true" v-model="member" @change="dataChange" value-key="Key" :clearable="clearable" @clear='dataChange' :disabled='disabled'>
    <el-option v-for='item in datas' :key="item.Key" :label="item.RealName" :value="item"></el-option>
  </el-select>
</template>
<script>
import { getAll } from "@/api/setting/member";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      default:"",
    },
    clearable: {
      default: true
    },
    disabled: {
      default: false
    },
    source:
    {
      default:"",
    }
  },
  data () {
    return {
      datas: [],
      member: ''
    }
  },
  watch: {
    value: {
      handler (v) {
        this.member = v
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getData () {
      let param = {
        search: "",
        source:this.source,
      }
      getAll(param).then(res => {
        if (res) {
          this.datas = res.Items;
        }
      })
    },
    dataChange (v) {
      this.$emit("change", v)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang='scss' scoped>
</style>