<template>
  <el-drawer :title="formTitle" size="50%" :visible.sync="formVisible">
    <el-form :model="dataForm" ref="dataForm" label-width="120px" :rules="formRules">
      <el-form-item label="指导陪练:" prop="Coach">
        <memberSelector v-model="dataForm.Coach" source="PC" />
      </el-form-item>
      <el-form-item v-if="!readOnly">
        <div class="footer">
          <el-button type="primary" @click="handleSubmit">提交</el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>
<script>
import { save } from "@/api/camp/campCustomer";
import memberSelector from "@/components/memberSelector.vue";
export default {
  components: {
    memberSelector,
  },
  props: {
  },
  data() {
    return {
      readOnly: false,
      formVisible: false,
      formTitle: "新增",
      dataForm: {
      },
      formRules: {
        Coach: [
          { required: true, message: "请选择陪练", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    handleView(row) {
      this.readOnly = true;
      this.dataForm = JSON.parse(JSON.stringify(row));
      this.formTitle = "查看";
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleCreate() {
      this.formTitle = "新增";
      this.formVisible = true;
      this.readOnly = false;
      this.dataForm = {

      };
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.dataForm = JSON.parse(JSON.stringify(row));
      this.readOnly = false;
      this.formTitle = "修改";
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleSubmit: function () {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.dataForm);
          save(data).then((response) => {
            if (response) {
              this.formVisible = false;
              this.$emit("update");
            }
          });
        } else {
          setTimeout(() => {
            this.$refs["dataForm"].clearValidate();
          }, 1000);
        }
      });
    },
  },
  mounted() { },
};
</script>
<style lang='scss' scoped>
/deep/.el-drawer__header {
  margin-bottom: 10px;
  font-size: 16px;
  color: #ff862f;
}

/deep/.el-drawer__body {
  overflow-y: scroll;
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.invoice-container {
  padding: 10px;

  .input {
    width: 150px;
  }

  .tabs-container {
    /deep/.el-tabs--border-card>.el-tabs__content {
      padding: 0;
      height: 1px;
    }

    .tab-content {
      margin-top: -2px;
      border: 1px solid #dcdfe6;
      border-top: 0;
      padding: 15px;
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }
  }
}
</style>