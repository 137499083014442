import { render, staticRenderFns } from "./campCustomerForm.vue?vue&type=template&id=ede6e170&scoped=true&"
import script from "./campCustomerForm.vue?vue&type=script&lang=js&"
export * from "./campCustomerForm.vue?vue&type=script&lang=js&"
import style0 from "./campCustomerForm.vue?vue&type=style&index=0&id=ede6e170&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ede6e170",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\source\\200xth\\xth2.web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ede6e170')) {
      api.createRecord('ede6e170', component.options)
    } else {
      api.reload('ede6e170', component.options)
    }
    module.hot.accept("./campCustomerForm.vue?vue&type=template&id=ede6e170&scoped=true&", function () {
      api.rerender('ede6e170', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/camp/components/campCustomerForm.vue"
export default component.exports