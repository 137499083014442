import request from '@/utils/request'

export function getPageList(data) {
    return request({
        url: 'campCustomer/getPageList',
        method: 'post',
        data,
    })
}
export function save(data) {
    return request({
        url: 'campCustomer/save',
        method: 'post',
        data,
    })
}

export function del(data) {
    return request({
        url: 'campCustomer/delete',
        method: 'post',
        data,
    })
}

export function addCustomer(data) {
    return request({
        url: 'campAppoint/addCustomer',
        method: 'post',
        data,
    })
}